import React from 'react';
import PropTypes from 'prop-types';
import './ymirheader.scss';

export const YmirHeader = (props) => {
  const {
    title, subtitle, subtitle2,
  } = props;
  return (
    <div className="ymir-header-container">
      <h3 className="ymir-header-title">{title}</h3>
      <div className="text-inner" dangerouslySetInnerHTML={{ __html: subtitle }} />
      <p className="ymir-header-subtitle2">{subtitle2}</p>
    </div>

  );
};

YmirHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitle2: PropTypes.string.isRequired,
};
