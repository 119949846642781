import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Ymir from '../src/components/Ymir/Ymir';
import { YmirHeader } from '../src/components/Titles/YmirHeader/YmirHeaderv2';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import Layout from '../src/components/Layout/Layout';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Buttons from '../src/components/Buttons/Buttons';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import './easteregg.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query easteregg($locale: String!) {
    allEastereggRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
    allEastereggSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allEastereggTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allEastereggBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allEastereggContentBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
          cta
          ctaText
        }
      }
    }
    allEastereggInformativeBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          content
        }
      }
    }
    allEastereggImageHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allEastereggImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allEastereggImageMobileBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

class EasterEgg extends React.Component {
  state = {};

  render() {
    const ymirData = {
      title: this.props.data.allEastereggInformativeBlock.edges[0].node.title,
      subtitle: this.props.data.allEastereggInformativeBlock.edges[0].node.content,
      cards: [
        {
          title: tt('GRUPO ESCOLAR (4-17 AÑOS)', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('grupos/grupos-escolares', this.props.pageContext.locale),
          icon: 'grupos1',
        },
        {
          title: tt('GRUPO VACACIONAL', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/grupos/grupos-vacacionales', this.props.pageContext.locale),
          icon: 'grupos2',
        },
        {
          title: tt('FAMILIAS NUMEROSAS', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/entradas/familia-numerosa', this.props.pageContext.locale),
          icon: 'grupos3',
        },
      ],
    };

    const titleHome = {
      name: this.props.data.allEastereggTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allEastereggTitleBlock.edges[0].node.description,
      subtitleType: 'noborder',
      image: {
        url: this.props.data.allEastereggImageHeader.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allEastereggImageHeader.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.allEastereggImageMobileBlock.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allEastereggImageMobileBlock.edges[0].node.alt,
      },
    };

    const h2Data = {
      title: this.props.data.allEastereggContentBlock.edges[0].node.title,
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allEastereggSeoData.edges[0].node._0.title}
          description={this.props.data.allEastereggSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Hod
            data={this.props.data.allEastereggRichData.edges[0].node.rich_markup}
            url={this.props.pageContext.url}
            img={this.props.data.allEastereggImageHeader.edges[0].node.image}
          />
          <Heimdall data={titleHome} />

          <div className="general-index general-aniversario">
            <div className="easteregg-index">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allEastereggBreadcrumbBlock.edges[0].node.name}
              />
              <div className="easteregg-image">
                <Img
                  fluid={
                    this.props.data.allEastereggImageBlock.edges[0].node.localImage.childImageSharp
                      .fluid
                  }
                />
              </div>
              <H2 data={h2Data} />
              <div
                className="easteregg-text"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allEastereggContentBlock.edges[0].node.body,
                }}
              />
              <Buttons
                link={this.props.data.allEastereggContentBlock.edges[0].node.cta}
                size="alone"
                color="orange"
                text={this.props.data.allEastereggContentBlock.edges[0].node.ctaText}
              />
              <div className="easteregg-content-promo">
                <YmirHeader
                  title={ymirData.title}
                  subtitle={ymirData.subtitle}
                  subtitle2={ymirData.subtitle2}
                />
                <Ymir data={ymirData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EasterEgg;
